<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="场地名称" name="name" class="ui-form__item">
						<a-input v-model:value="formState.name" placeholder="请输入"></a-input>
					</a-form-item>
					
					<a-form-item label="场地编码" name="code" class="ui-form__item">
						<a-input v-model:value="formState.code" placeholder="请输入"></a-input>
					</a-form-item>
					
					<a-form-item label="所属影院" name="cinemaId" class="ui-form__item">
						<cinema v-model:value="formState.cinemaId"></cinema>
					</a-form-item>
					
					<a-form-item label="所属影城" name="hallId" class="ui-form__item">
						<hall v-model:value="formState.hallId" :cinemaId="formState.cinemaId"></hall>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择" style="width: 200px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="16">
						<a-button type="primary" @click="onAdd">新增场地</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<cTable ref="cTable" :isPage="true" :isRequest="true" :searchData="searchData" :requestFun="getPerformSiteList" :columns="columns">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'isDisabled'">
						{{ record.isDisabled ? '已禁用' : '已启用' }}
					</template>
					<template v-if="column.key == 'action'">
						<a-dropdown>
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
							<template #overlay>
								<a-menu>
									<div @click="toSee(record)">
										<a-menu-item>
											<a>查看</a>
										</a-menu-item>
									</div>
									<div @click="toEdit(record)">
										<a-menu-item>
											<a>编辑</a>
										</a-menu-item>
									</div>
									<div v-if="record.isDisabled" @click="onDisabled(record)">
										<a-menu-item>启用</a-menu-item>
									</div>
									<div v-else @click="onDisabled(record)">
										<a-menu-item>禁用</a-menu-item>
									</div>
									<div @click="onDelete(record)">
										<a-menu-item>删除</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</cTable>
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import { Icon } from "@/components/icon/icon.js";
	import temp from './temp.vue';
	import cTable from '@/components/cTable/index.vue';
	import cinema from '@/views/performance/components/cinema/cinema.vue';
	import hall from '@/views/performance/components/cinema/hall.vue';
	import {
		getPerformSiteList,
		disabledPerformSite,
		deletePerformSite
	} from "@/service/modules/perform";
	export default {
		components: {
			Icon,
			cinema,
			hall,
			cTable,
			temp
		},
		data() {
			return {
				loading: false,
				showModal: false,
				isEdit: false,
				isSee: false,
				formState: {},
				searchData: {},
				list: [],
				columns: [{
					title: '场地编码',
					dataIndex: 'code'
				}, {
					title: '场地名称',
					dataIndex: 'name'
				}, {
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '所属影厅',
					dataIndex: 'hallName'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
				}],
				id: 0,
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getPerformSiteList: getPerformSiteList,
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			reset() {
				this.$refs.formRef.resetFields();
				this.onSearch()
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData(true);
					})
				}
			},
			onAdd() {
				this.id = 0;
				this.isSee = false;
				this.isEdit = false;
				this.showModal = true;
			},
			toEdit(item) {
				this.id = item.id;
				this.isSee = false;
				this.isEdit = true;
				this.showModal = true;
			},
			toSee(item) {
				this.id = item.id;
				this.isSee = true;
				this.isEdit = false;
				this.showModal = true;
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '禁用' : '启用' }该选项吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await disabledPerformSite({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '禁用' : '启用' }成功！`);
							this.getData();
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePerformSite({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功！');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>